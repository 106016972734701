<template>
	<article class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_winterhealthcare/title.png" alt="冬のココロの健康管理～太陽の光でポジティブに～ presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<h2 class="lp__title"><img src="~@/assets/img/lp_winterhealthcare/cause_subtitle01.png" alt="冬に心の不調が起きる原因"></h2>
		<!-- /イントロ -->

		<!-- 原因1 -->
		<section class="lp__contents is-cause1" id="js_cause1">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/cause01.png" alt="原因1"></span>
				<p>日照時間の激減!!</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__text lp__contents--text">日本海側では、晩秋から冬にかけて冬型の気圧配置になって雪雲に覆われる日が増えて、日照時間が極端に少なくなる傾向があります。札幌では、10月から12月にかけて日照時間が激減し、12月の日照時間は合計で1か月合計で80時間ほど(1日平均で約2.7時間)、一番多い5月の4割程度しかありません。</p>
			<p class="lp__contents--img"><img src="~@/assets/img/lp_winterhealthcare/cause_img01.png" alt="月別日照時間イメージ"></p>
			<p class="lp__contents--comment is-hiroshi-3line"><span>日本海側以外の地域でも<br>冬に日ざしが減る地域は多いんじゃ！</span></p>

			<!-- アプリの天気予報 -->
			<section class="lp__app is-weather-forecast" id="js_weatherForecast">
				<h4 class="lp__app--title"><em>頭痛ーるアプリの天気予報</em></h4>
				<p class="lp__text lp__app--text">頭痛ーるアプリは天気に加えて、日の出、日の入り時間まで確認する事ができますので朝の生活習慣の手助けになります。</p>
				<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img01.png" alt="天気予報イメージ"></p>
				<p class="lp__hiroshi lp__app--comment"><span>日の出の時間と朝の天気を<br>チェックじゃ！</span></p>
				<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
				<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
			</section>
			<!-- /アプリの天気予報 -->
		</section>
		<!-- /原因1 -->

		<!-- 原因2 -->
		<section class="lp__contents is-cause2" id="js_cause2">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/cause02.png" alt="原因2"></span>
				<p>セロトニン減少でうつ!?</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__text lp__contents--text">光を受ける量が減ると、脳内で作られる神経伝達物質<em>「セロトニン」の分泌が減り</em>、心のバランスが崩れやすくなります。<br>セロトニンは<em>幸せのホルモン</em>とも呼ばれ、分泌量が少なくなると、ちょっとしたことで<em>イライラしやすくなったり</em>、<em>一日中シャキッとしない状態</em>になったり、<em>落ち込みがちのネガティブな状態</em>に陥ったりするケースがあります。</p>
			<p class="lp__contents--box"><span>セロトニンが減るとイライラするんだニャ！</span></p>
			<p class="lp__text lp__contents--text is-center">健康天気指数で<br>日々の体調管理をサポート！</p>
			<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })"><span class="is-small">頭痛ーるで毎日更新！</span><span class="is-large">うつ指数をチェック</span></a>
		</section>
		<!-- /原因2 -->

		<!-- 原因3 -->
		<section class="lp__contents is-cause3" id="js_cause3">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/cause03.png" alt="原因3"></span>
				<p>メラトニン減少で不眠!?</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__text lp__contents--text">光を浴びる量が減ると、<em>体内時計を調整する働きがある「メラトニン」の分泌にも影響</em>します。メラトニンの分泌量が少なくなると、<em>寝つきが悪くなったり</em>、<em>不眠症や睡眠障害の原因</em>にもなりえます。</p>
			<p class="lp__contents--box"><span>メラトニンが減ると寝つきが悪くなるニャ！</span></p>
			<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022habit' } })"><span class="is-small">うつや不眠を予防！！</span><span class="is-large">今すぐ実践したい朝の習慣</span></a>

			<!-- アプリの記録機能 -->
			<section class="lp__app is-recording-function" id="js_recordingFunction">
				<h4 class="lp__app--title"><em>頭痛ーるアプリの記録機能</em></h4>
				<p class="lp__text lp__app--text">頭痛ーるアプリば頭痛の痛みだけでなく、体調や飲んだ薬をアプリで記録でき、体調管理に役立てる事ができます。</p>
				<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img02.png" alt="記録イメージ"></p>
				<p class="lp__hiroshi is-tight lp__app--comment"><span>自分が不調になりやすいタイミングが分かりそうじゃ！</span></p>
				<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
				<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
				<p class="lp__maro is-3line lp__contents--comment"><span>自分の状態に心配な点があれば、まずはストレスチェックだニャ！</span></p>
				<a class="lp__button lp__stresscheck--button" href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stresscheckDiagnosis` } })">
					<img src="~@/assets/img/lp_winterhealthcare/stresscheck_banner.png" alt="1分で診断! 医師監修 今の自分がわかるストレスチェック">
				</a>
				<p class="lp__stresscheck--annotation"><small>監修:アイさくらクリニック 木村昌幹 先生</small></p>
				<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022habit' } })"><span class="is-small">うつや不眠を予防！！</span><span class="is-large">今すぐ実践したい朝の習慣</span></a>
			</section>
			<!-- /アプリの記録機能 -->
		</section>
		<!-- /原因3 -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title">太陽の光を味方に<br>”with頭痛ーるアプリ”で<br>ココロも体も健康な冬に</h2>
			<div class="lp__outro--img"><img src="@/assets/img/lp_winterhealthcare/outro.png" alt="マロと雪だるま"></div>
			<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022top' } })"><span>冬のココロの健康管理</span><span class="is-margin-top4">TOPへ</span></a>
			<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="lp__outro--button is-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button is-back">TOPにもどる</a>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022原因リンクAndroid" data-gtm-event-act-label="trackingLpWinterHelthcare2022causeBtn_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022原因リンクiOS" data-gtm-event-act-label="trackingLpWinterHelthcare2022causeBtn_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpWinterHealthcare2022Cause',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			$html: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		// TODO: Webview、スゴ得でリポジトリをわける際、common.scssでルートフォントをvwで指定するようにしたい。もしできたらこちらの処理は不要なので削除。
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}
	}
}
</script>

<style lang="scss" scoped>
// 特集共通SASS
@import "~@/assets/sass/lp_winterhealthcare/lp_common";

.lp {
	&__title { margin: 0 auto; }

	&__contents {
		$parent: &;

		&--comment.is-hiroshi-3line {
			position: relative;
			width: 81.3948%;
			padding-top: 23.6312%;
			margin: $spacing-10 auto 0;
			background: url(~@/assets/img/lp_winterhealthcare/hiroshi02.png) no-repeat 0 0;
			background-size: 100% auto;

			& > span {
				position: absolute;
				display: block;
				width: 64.4385%;
				top: 50%;
				right: $spacing-6;
				transform: translateY(-50%);
				font-size: $font-size-14;
			}
		}

		&--box {
			position: relative;
			width: 88.4727%;
			padding-top: 75.2162%;
			margin: $spacing-10 auto 0;

			& > span {
				position: absolute;
				display: block;
				width: 38.4365%;
				top: 50%;
				right: $spacing-16;
				font-size: $font-size-14;
			}
		}

		&--text.is-center {
			margin-top: $spacing-20;
			padding: 0;
			text-align: center;
		}

		&.is-cause1 { padding-bottom: $spacing-20; }

		&.is-cause2 {
			padding-bottom: $spacing-24;

			#{$parent}--box {
				background: url(~@/assets/img/lp_winterhealthcare/cause_bg01.png) no-repeat 0 0;
				background-size: 100% auto;
			}

			#{$parent}--button { margin-top: $spacing-12; }
		}

		&.is-cause3 {
			margin-top: $spacing-20;
			padding-bottom: $spacing-26;

			#{$parent}--box {
				margin: $spacing-14 auto 0;
				background: url(~@/assets/img/lp_winterhealthcare/cause_bg02.png) no-repeat 0 0;
				background-size: 100% auto;
			}

			& > #{$parent}--button { margin-top: $spacing-20; }
		}
	}

	&__app.is-recording-function {
		margin-top: $spacing-24;

		& > .lp__contents--button { margin-top: $spacing-10; }
	}

	&__stresscheck {
		&--button {
			width: 86.4554%;
			margin: $spacing-10 auto 0;
		}

		&--annotation { margin: $spacing-8 0 0; }
	}

	&__outro {
		.lp__contents--button {
			width: 80%;
			margin-top: $spacing-20;
		}

		&--button.is-pagetop { margin-top: $spacing-24; }
	}
}
</style>
